<template>
  <div class="product-center">
    <v-nav-bar />
    <v-banner-box :data="swiperData" />
    <div
      id="overview"
      style="display: none"
    />
    <div
      class="banner100vw banner100vw-20p30t banner100vw-linear-gradient"
      id="scenarios"
    >
      <v-app-scenarios />
    </div>
    <v-industry />
    <div
      class="banner100vw banner100vw-20p30t banner100vw-linear-gradient"
      id="frame"
    >
      <v-frame />
    </div>
    <v-technology />
    <div
      class="
        banner100vw banner100vw-20p30t banner100vw-linear-gradient
        productadvantage-bg
      "
      id="advantage"
    >
      <v-productadvantage />
    </div>
    <v-app-stories :case-list="caseList" />
  </div>
</template>

<script>
// NOTE :  产品中心
import vBannerBox from '@/components/banner/bannerBox.vue'
import vNavBar from './components/navBar/index.vue'
import vAppScenarios from './components/appScenarios/index.vue'
import vIndustry from './components/industry/index.vue'
import vFrame from './components/frame/index.vue'
import vTechnology from './components/technology/index.vue'
import vProductadvantage from './components/productadvantage/index.vue'
import vAppStories from './components/appStories/index.vue'
export default {
  components: {
    vNavBar,
    vBannerBox,
    vAppScenarios,
    vIndustry,
    vFrame,
    vTechnology,
    vProductadvantage,
    vAppStories
  },
  data () {
    return {
      swiperData: [],
      caseList: []
    }
  },
  methods: {
    freeTrial () {
      console.log('免费试用')
    },
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['产品中心']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    // 获取案例 - 产品中心
    getCaseList () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.caseTypes['产品中心']
      }
      this.$api
        .getCaseInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.caseList = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    }
  },
  mounted () {
    this.getSwiperData()
    this.getCaseList()
  }
}
</script>

<style lang="less" scoped>
.product-center {
  .banner100vw();
  .productadvantage-bg {
    &::before {
      background: url('/static/img/theSolution/a-1.png') no-repeat center center /
          100% auto,
        linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    }
  }
}
</style>
